import React, { useEffect, useRef } from "react";
import { MemoDice } from './Dice.js'
import get from "./client.js";

function Grid({ sse, nextPage, setNextPage, items, setItems }) {
	const observerTarget = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			async entries => {
				if (entries[0].isIntersecting) {
					if (items.length !== 0 && items[items.length - 1].index === 999999) {
						return
					}
					const result = await get("/load/" + nextPage);
					const newDices = items.concat(result.dices)
					setItems(newDices);
					setNextPage(result.nextPage);
				}
			},
			{ threshold: 1 }
		);


		if (observerTarget.current) {
			observer.observe(observerTarget.current);
		}
		const current = observerTarget.current;

		return () => {
			if (current) {
				observer.unobserve(current);
			}
		};
	}, [nextPage, items, setItems, setNextPage, observerTarget]);
	return (
		<div>
			<div className="flex flex-wrap justify-center px-6 py-2">
				{items.map((data) => (
					<MemoDice key={data.index} data={data} sse={sse} />
				))}
				<div ref={observerTarget}></div>
			</div>
		</div>

	);
}


export default Grid;


function Counter({ number }) {
	return (
		<h3 className="text-2xl self-center font-pacifico min-w-64 flex flex-row-reverse justify-center mt-4 grow">
			<span id="counter" className="pl-3 space-mono-bold-italic">{number}</span>
			Dices Rolled
		</h3>
	);
}

export default Counter;

class SSE {
	es = null
	handlers = []
	constructor(base) {
		this.es = new EventSource(base + "/updates");
		this.es.addEventListener('connection', (event) => {
			//TODO: remove this log
			console.log('connection event received:', event.data);
		});
	}
	addHandler(type, handler) {
		this.es.addEventListener(type, handler);
	}
}

const sse = new SSE("http://localhost:8080");
export default sse;

import Contact from './Contact.js'
import Popup from './Popup.js'
import Counter from './Counter.js'
import './index.css'
import Grid from './Grid.js'
import sse from './SSE.js'
import { useState, useEffect } from 'react'
import get from './client.js'


function App() {
	const [counter, setCounter] = useState(0)
	const [page, setPage] = useState(0);
	const [items, setItems] = useState([]);
	const [loads, setLoads] = useState([]);
	const [step, setStep] = useState(0);

	sse.addHandler('counter', (event) => {
		const d = JSON.parse(event.data)
		setCounter(d.counter)
	})

	useEffect(() => {
		get("").then(res => {
			setLoads(res.loads)
			setStep(res.step)
			setCounter(res.counter)
		});
	}, [setLoads, setStep, setCounter])

	const reload = value => {
		setItems([])
		setPage(value)
	}

	return (
		<div>
			<title>One Million Dice</title>
			<div className="text-center flex sticky top-0 bg-zinc-200 border-4 border-b-zinc-600 mb-6
		rounded-t-lg select-none z-50 lg:pt-2 pb-6 lg:px-12 flex-col lg:flex-row justify-between">
				<Contact />
				<h3 className="xs:text-2xl md:text-4xl lg:text-5xl self-center font-pacifico grow mt-2">One Million
					Dice
				</h3>
				<div>
					<Popup reload={reload} loads={loads} step={step} />
					<Counter number={counter} />
				</div>
			</div>
			<Grid sse={sse} nextPage={page} setNextPage={setPage} items={items} setItems={setItems} />
		</div>
	);
}


export default App;

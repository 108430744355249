import React, { useRef } from "react";

const Contact = () => {
  const emailRef = useRef();
  const tooltipRef = useRef();
  const defaultIconRef = useRef();
  const successIconRef = useRef();
  const defaultTooltipMessageRef = useRef();
  const successTooltipMessageRef = useRef();

  const showTooltip = () => {
    tooltipRef.current.classList.remove("invisible", "opacity-0");
  };

  const hideTooltip = () => {
    tooltipRef.current.classList.add("invisible", "opacity-0");
  };

  const showSuccess = () => {
    navigator.clipboard.writeText(emailRef.current.value);

    successTooltipMessageRef.current.classList.remove("hidden");
    successIconRef.current.classList.remove("hidden");

    defaultIconRef.current.classList.add("hidden");
    defaultTooltipMessageRef.current.classList.add("hidden");

    tooltipRef.current.classList.remove(
      "invisible",
      "opacity-0",
      "translate-x-32"
    );
    tooltipRef.current.classList.add("translate-x-36");

    setTimeout(() => {
      resetToDefault();
    }, 1000);
  };

  const resetToDefault = () => {
    defaultIconRef.current.classList.remove("hidden");
    defaultTooltipMessageRef.current.classList.remove("hidden");

    successIconRef.current.classList.add("hidden");
    successTooltipMessageRef.current.classList.add("hidden");

    tooltipRef.current.classList.add("invisible", "opacity-0", "translate-x-32");
    tooltipRef.current.classList.remove("translate-x-36");
  };

  return (
    <div className="self-center w-fit">
      <div className="text-xl font-lexendDeca">
        <span className="inline-flex p-1">
          a website by
          <a
            className="text-sky-500"
            href="https://www.linkedin.com/in/fuad-daoud"
          >
            <strong>&nbsp;fuad&nbsp;</strong>
          </a>
        </span>
      </div>

      <div className="w-full max-w-[16rem]">
        <div className="relative">
          <label htmlFor="email-copy" className="sr-only">
            Label
          </label>
          <input
            id="email-copy"
            type="text"
            ref={emailRef}
            className="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-gray-400 light:focus:ring-blue-500 light:focus:border-blue-500 select-none"
            value="fuad.daoud@icloud.com"
            disabled
            readOnly
          />
          <button
            aria-label="copy"
            onClick={showSuccess}
            onMouseOver={showTooltip}
            onMouseLeave={hideTooltip}
            className="absolute end-2 top-1/2 -translate-y-1/2 text-gray-500 light:text-gray-400 hover:bg-gray-100 light:hover:bg-gray-800 rounded-lg p-2 inline-flex items-center justify-center"
            id="copy-button">
            <span id="default-icon" ref={defaultIconRef}>
              <svg
                className="w-3.5 h-3.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 20">
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
              </svg>
            </span>
            <span id="success-icon" ref={successIconRef} className="hidden">
              <svg
                className="w-3.5 h-3.5 text-blue-700 light:text-blue-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
            </span>
          </button>
          <div
            id="tooltip-copy-email-copy"
            ref={tooltipRef}
            role="tooltip"
            className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip light:bg-gray-700 m-0 inset-custom -translate-y-full translate-x-32"
            data-popper-placement-top>
            <span
              id="default-tooltip-message"
              ref={defaultTooltipMessageRef}>
              Copy to clipboard
            </span>
            <span
              id="success-tooltip-message"
              ref={successTooltipMessageRef}
              className="hidden">
              Copied!
            </span>
          </div>
          <div className="translate-x-36"></div>
        </div>
      </div>
    </div>
  );
};

export default Contact;


const url = ""
export default async function get(path) {
	return fetch(url + path, {
		headers: {
			"Accept": "application/json",
		},
	}).then(res => res.json());
}
export async function post(path) {
	return fetch(url + path, {
		headers: {
			"Accept": "application/json",
		},
		method: 'POST',
	});
}



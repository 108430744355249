import React, { useState, useEffect, useRef, memo } from "react";
import RedDice from "./Dice.js";

function Popup({ reload, loads, step }) {
	const [isModalVisible, setModalVisible] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [, setSelectedValue] = useState(null); // Retain for future use if needed.

	const modalRef = useRef();
	const searchInputRef = useRef();



	const filteredLoads = loads.filter((item) =>
		searchTerm ? item.value * step >= parseInt(searchTerm) : true
	);

	const handleDocumentClick = (e) => {
		//TODO: fix this 
		if (modalRef.current && isModalVisible && !modalRef.current.contains(e.target)) {
			hideModal();
		}
	};

	const showModal = () => {
		setModalVisible(true);
		setSearchTerm("");
		document.addEventListener("click", handleDocumentClick);
	};

	const hideModal = () => {
		setModalVisible(false);
		setSearchTerm("");
		document.removeEventListener("click", handleDocumentClick);
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter" && filteredLoads.length > 0) {
			const firstItem = filteredLoads[0];
			setSelectedValue(firstItem);
			hideModal();
		} else if (e.key === "Escape") {
			hideModal();
		}
	};

	useEffect(() => {
		if (isModalVisible && searchInputRef.current) {
			searchInputRef.current.focus();
		}
	}, [isModalVisible]);

	useEffect(() => {
		function handleKeyDown(e) {
			if (e.key === "Escape") {
				hideModal();
			}
		}

		document.addEventListener('keydown', handleKeyDown);

		return function cleanup() {
			document.removeEventListener('keydown', handleKeyDown);
		}
	});

	const placeHolder = "Step is " + step;

	return (
		<div>
			<h3 id="new-start" onClick={showModal} className="text-2xl self-center font-pacifico min-w-64 flex flex-row-reverse justify-center mt-4 cursor-pointer hover:underline">
				New Start?
			</h3>

			{isModalVisible && (
				<div id="popup-modal" ref={modalRef} className="place-self-center fixed z-50 justify-center items-center inset-0 p-2 bg-zinc-200 rounded-lg  sm:w-64 lg:w-96 h-96 border-black border-2 overflow-hidden border-b-4">
					<div className="flex flex-row-reverse m-1">
						<div id="close" className="border-2 hover:border-red-500 rounded-full border-transparent cursor-pointer" onClick={hideModal}>
							<RedDice />
						</div>
					</div>
					<div className="p-4 md:p-5 text-center">
						<h3 className="text-lg font-pacifico">New Start?</h3>
					</div>
					<input id="search-input" ref={searchInputRef} type="number" placeholder={placeHolder} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onKeyUp={handleKeyPress} className="block w-full text-center outline-none border-b-zinc-500 border-2 bg-zinc-200 mb-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" min="0" max="1000000" />
					<div className="overflow-y-auto max-h-40">
						{filteredLoads.map((item, index) => (
							<span key={index} className="option block hover:bg-zinc-500 active:bg-blue-100 cursor-pointer text-center" onClick={() => {
								reload(item.value)
								setSelectedValue(item);
								hideModal();
							}}>
								{item.display}
							</span>
						))}
					</div>
				</div>
			)}
		</div>
	);
}

export default memo(Popup);

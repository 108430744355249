import { useState, memo } from 'react'
import { post } from './client';

function One() {
	return (
		<path d="M 30.714 20.843 C 28.886 20.843 27.097 21.38 25.571 22.387 C 24.05 23.394 22.864 24.822 22.161 26.497 C 21.462 28.168 21.277 30.007 21.633 31.784 C 21.993 33.564 22.872 35.194 24.169 36.475 C 25.467 37.758 27.114 38.627 28.912 38.98 C 30.705 39.335 32.569 39.151 34.261 38.461 C 35.954 37.766 37.4 36.593 38.416 35.086 C 39.43 33.581 39.976 31.809 39.976 30 C 39.976 27.574 39.001 25.244 37.263 23.525 C 35.527 21.807 33.172 20.843 30.714 20.843 Z"
			fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)"></path>
	);
}
function Two() {
	return (
		<>
			<path d="M 41.232 20.808 C 39.404 20.808 37.615 21.345 36.089 22.352 C 34.568 23.359 33.382 24.787 32.679 26.462 C 31.98 28.133 31.795 29.972 32.151 31.749 C 32.511 33.529 33.39 35.159 34.687 36.44 C 35.985 37.723 37.632 38.592 39.43 38.945 C 41.223 39.3 43.087 39.116 44.779 38.426 C 46.472 37.731 47.918 36.558 48.934 35.051 C 49.948 33.546 50.494 31.774 50.494 29.965 C 50.494 27.539 49.519 25.209 47.781 23.49 C 46.045 21.772 43.69 20.808 41.232 20.808 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)"></path>
			<path d="M 20.196 20.879 C 18.368 20.879 16.579 21.416 15.053 22.423 C 13.532 23.43 12.346 24.858 11.643 26.533 C 10.944 28.204 10.759 30.043 11.115 31.82 C 11.475 33.6 12.354 35.23 13.651 36.511 C 14.949 37.794 16.596 38.663 18.394 39.016 C 20.187 39.371 22.051 39.187 23.743 38.497 C 25.436 37.802 26.882 36.629 27.898 35.122 C 28.912 33.617 29.458 31.845 29.458 30.036 C 29.458 27.61 28.483 25.28 26.745 23.561 C 25.009 21.843 22.654 20.879 20.196 20.879 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)"></path>
		</>
	);
}
function Three() {
	return (
		<>
			<path d="M 41.232 30.179 C 39.404 30.179 37.615 30.716 36.089 31.723 C 34.568 32.73 33.382 34.158 32.679 35.833 C 31.98 37.504 31.795 39.343 32.151 41.12 C 32.511 42.9 33.39 44.53 34.687 45.811 C 35.985 47.094 37.632 47.963 39.43 48.316 C 41.223 48.671 43.087 48.487 44.779 47.797 C 46.472 47.102 47.918 45.929 48.934 44.422 C 49.948 42.917 50.494 41.145 50.494 39.336 C 50.494 36.91 49.519 34.58 47.781 32.861 C 46.045 31.143 43.69 30.179 41.232 30.179 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)"></path>
			<path d="M 20.196 30.25 C 18.368 30.25 16.579 30.787 15.053 31.794 C 13.532 32.801 12.346 34.229 11.643 35.904 C 10.944 37.575 10.759 39.414 11.115 41.191 C 11.475 42.971 12.354 44.601 13.651 45.882 C 14.949 47.165 16.596 48.034 18.394 48.387 C 20.187 48.742 22.051 48.558 23.743 47.868 C 25.436 47.173 26.882 46 27.898 44.493 C 28.912 42.988 29.458 41.216 29.458 39.407 C 29.458 36.981 28.483 34.651 26.745 32.932 C 25.009 31.214 22.654 30.25 20.196 30.25 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)"></path>
			<path d="M 30.714 11.437 C 28.886 11.437 27.097 11.974 25.571 12.981 C 24.05 13.988 22.864 15.416 22.161 17.091 C 21.462 18.762 21.277 20.601 21.633 22.378 C 21.993 24.158 22.872 25.788 24.169 27.069 C 25.467 28.352 27.114 29.221 28.912 29.574 C 30.705 29.929 32.569 29.745 34.261 29.055 C 35.954 28.36 37.4 27.187 38.416 25.68 C 39.43 24.175 39.976 22.403 39.976 20.594 C 39.976 18.168 39.001 15.838 37.263 14.119 C 35.527 12.401 33.172 11.437 30.714 11.437 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)"></path>
		</>
	);
}
function Four() {
	return (
		<>
			<path d="M 41.232 30.433 C 39.404 30.433 37.615 30.97 36.089 31.977 C 34.568 32.984 33.382 34.412 32.679 36.087 C 31.98 37.758 31.795 39.597 32.151 41.374 C 32.511 43.154 33.39 44.784 34.687 46.065 C 35.985 47.348 37.632 48.217 39.43 48.57 C 41.223 48.925 43.087 48.741 44.779 48.051 C 46.472 47.356 47.918 46.183 48.934 44.676 C 49.948 43.171 50.494 41.399 50.494 39.59 C 50.494 37.164 49.519 34.834 47.781 33.115 C 46.045 31.397 43.69 30.433 41.232 30.433 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)"></path>
			<path d="M 20.196 30.504 C 18.368 30.504 16.579 31.041 15.053 32.048 C 13.532 33.055 12.346 34.483 11.643 36.158 C 10.944 37.829 10.759 39.668 11.115 41.445 C 11.475 43.225 12.354 44.855 13.651 46.136 C 14.949 47.419 16.596 48.288 18.394 48.641 C 20.187 48.996 22.051 48.812 23.743 48.122 C 25.436 47.427 26.882 46.254 27.898 44.747 C 28.912 43.242 29.458 41.47 29.458 39.661 C 29.458 37.235 28.483 34.905 26.745 33.186 C 25.009 31.468 22.654 30.504 20.196 30.504 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)"></path>
			<path d="M 41.232 11.183 C 39.404 11.183 37.615 11.72 36.089 12.727 C 34.568 13.734 33.382 15.162 32.679 16.837 C 31.98 18.508 31.795 20.347 32.151 22.124 C 32.511 23.904 33.39 25.534 34.687 26.815 C 35.985 28.098 37.632 28.967 39.43 29.32 C 41.223 29.675 43.087 29.491 44.779 28.801 C 46.472 28.106 47.918 26.933 48.934 25.426 C 49.948 23.921 50.494 22.149 50.494 20.34 C 50.494 17.914 49.519 15.584 47.781 13.865 C 46.045 12.147 43.69 11.183 41.232 11.183 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)"></path>
			<path d="M 20.196 11.254 C 18.368 11.254 16.579 11.791 15.053 12.798 C 13.532 13.805 12.346 15.233 11.643 16.908 C 10.944 18.579 10.759 20.418 11.115 22.195 C 11.475 23.975 12.354 25.605 13.651 26.886 C 14.949 28.169 16.596 29.038 18.394 29.391 C 20.187 29.746 22.051 29.562 23.743 28.872 C 25.436 28.177 26.882 27.004 27.898 25.497 C 28.912 23.992 29.458 22.22 29.458 20.411 C 29.458 17.985 28.483 15.655 26.745 13.936 C 25.009 12.218 22.654 11.254 20.196 11.254 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)"></path>
		</>
	);
}
function Five() {
	return (
		<>
			<path d="M 42.606 9.965 C 41.027 9.965 39.482 10.434 38.163 11.314 C 36.849 12.194 35.824 13.441 35.217 14.905 C 34.613 16.365 34.453 17.971 34.761 19.524 C 35.072 21.079 35.831 22.503 36.952 23.622 C 38.073 24.743 39.496 25.502 41.05 25.811 C 42.599 26.121 44.209 25.96 45.671 25.357 C 47.134 24.75 48.383 23.725 49.261 22.409 C 50.137 21.094 50.608 19.546 50.608 17.965 C 50.608 15.846 49.766 13.81 48.264 12.308 C 46.765 10.807 44.73 9.965 42.606 9.965 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)"></path>
			<path d="M 18.823 9.965 C 17.244 9.965 15.699 10.434 14.38 11.314 C 13.066 12.194 12.041 13.441 11.434 14.905 C 10.83 16.365 10.67 17.971 10.978 19.524 C 11.289 21.079 12.048 22.503 13.169 23.622 C 14.29 24.743 15.713 25.502 17.267 25.811 C 18.816 26.121 20.426 25.96 21.888 25.357 C 23.351 24.75 24.6 23.725 25.478 22.409 C 26.354 21.094 26.825 19.546 26.825 17.965 C 26.825 15.846 25.983 13.81 24.481 12.308 C 22.982 10.807 20.947 9.965 18.823 9.965 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)"></path>
			<path d="M 42.606 34.034 C 41.027 34.034 39.482 34.503 38.163 35.383 C 36.849 36.263 35.824 37.51 35.217 38.974 C 34.613 40.434 34.453 42.04 34.761 43.593 C 35.072 45.148 35.831 46.572 36.952 47.691 C 38.073 48.812 39.496 49.571 41.05 49.88 C 42.599 50.19 44.209 50.029 45.671 49.426 C 47.134 48.819 48.383 47.794 49.261 46.478 C 50.137 45.163 50.608 43.615 50.608 42.034 C 50.608 39.915 49.766 37.879 48.264 36.377 C 46.765 34.876 44.73 34.034 42.606 34.034 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)"></path>
			<path d="M 18.823 34.034 C 17.244 34.034 15.699 34.503 14.38 35.383 C 13.066 36.263 12.041 37.51 11.434 38.974 C 10.83 40.434 10.67 42.04 10.978 43.593 C 11.289 45.148 12.048 46.572 13.169 47.691 C 14.29 48.812 15.713 49.571 17.267 49.88 C 18.816 50.19 20.426 50.029 21.888 49.426 C 23.351 48.819 24.6 47.794 25.478 46.478 C 26.354 45.163 26.825 43.615 26.825 42.034 C 26.825 39.915 25.983 37.879 24.481 36.377 C 22.982 34.876 20.947 34.034 18.823 34.034 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)"></path>
			<path d="M 30.715 22 C 29.136 22 27.591 22.469 26.272 23.349 C 24.958 24.229 23.933 25.476 23.326 26.94 C 22.722 28.4 22.562 30.006 22.87 31.559 C 23.181 33.114 23.94 34.538 25.061 35.657 C 26.182 36.778 27.605 37.537 29.159 37.846 C 30.708 38.156 32.318 37.995 33.78 37.392 C 35.243 36.785 36.492 35.76 37.37 34.444 C 38.246 33.129 38.717 31.581 38.717 30 C 38.717 27.881 37.875 25.845 36.373 24.343 C 34.874 22.842 32.839 22 30.715 22 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)"></path>
		</>
	);
}
function Six() {
	return (
		<>
			<path d="M 40.871 8.843 C 39.521 8.843 38.202 9.243 37.076 9.995 C 35.953 10.746 35.078 11.812 34.56 13.062 C 34.043 14.309 33.906 15.681 34.17 17.007 C 34.436 18.336 35.084 19.552 36.041 20.507 C 36.999 21.465 38.215 22.113 39.541 22.377 C 40.864 22.642 42.24 22.504 43.488 21.99 C 44.738 21.471 45.804 20.595 46.554 19.472 C 47.302 18.348 47.706 17.026 47.706 15.676 C 47.706 13.866 46.986 12.127 45.703 10.845 C 44.423 9.562 42.685 8.843 40.871 8.843 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)" />
			<path d="M 20.558 8.843 C 19.209 8.843 17.889 9.243 16.763 9.995 C 15.641 10.746 14.765 11.812 14.247 13.062 C 13.73 14.309 13.594 15.681 13.857 17.007 C 14.122 18.336 14.771 19.552 15.728 20.507 C 16.686 21.465 17.902 22.113 19.229 22.377 C 20.552 22.642 21.926 22.504 23.175 21.99 C 24.425 21.471 25.491 20.595 26.241 19.472 C 26.99 18.348 27.391 17.026 27.391 15.676 C 27.391 13.866 26.673 12.127 25.39 10.845 C 24.109 9.562 22.371 8.843 20.558 8.843 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)" />
			<path d="M 40.871 37.492 C 39.521 37.492 38.202 37.893 37.076 38.645 C 35.953 39.396 35.078 40.461 34.56 41.712 C 34.043 42.959 33.906 44.33 34.17 45.657 C 34.436 46.985 35.084 48.201 36.041 49.157 C 36.999 50.115 38.215 50.762 39.541 51.027 C 40.864 51.291 42.24 51.153 43.488 50.638 C 44.738 50.121 45.804 49.245 46.554 48.121 C 47.302 46.998 47.706 45.675 47.706 44.325 C 47.706 42.516 46.986 40.776 45.703 39.494 C 44.423 38.212 42.685 37.492 40.871 37.492 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)" />
			<path d="M 20.558 37.492 C 19.209 37.492 17.889 37.893 16.763 38.645 C 15.641 39.396 14.765 40.461 14.247 41.712 C 13.73 42.959 13.594 44.33 13.857 45.657 C 14.122 46.985 14.771 48.201 15.728 49.157 C 16.686 50.115 17.902 50.762 19.229 51.027 C 20.552 51.291 21.926 51.153 23.175 50.638 C 24.425 50.121 25.491 49.245 26.241 48.121 C 26.99 46.998 27.391 45.675 27.391 44.325 C 27.391 42.516 26.673 40.776 25.39 39.494 C 24.109 38.212 22.371 37.492 20.558 37.492 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)" />
			<path d="M 40.871 23.107 C 39.521 23.107 38.202 23.507 37.076 24.259 C 35.953 25.01 35.078 26.076 34.56 27.326 C 34.043 28.573 33.906 29.944 34.17 31.271 C 34.436 32.6 35.084 33.815 36.041 34.771 C 36.999 35.729 38.215 36.377 39.541 36.641 C 40.864 36.905 42.24 36.768 43.488 36.254 C 44.738 35.734 45.804 34.859 46.554 33.736 C 47.302 32.612 47.706 31.289 47.706 29.94 C 47.706 28.129 46.986 26.391 45.703 25.108 C 44.423 23.826 42.685 23.107 40.871 23.107 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)" />
			<path d="M 20.558 23.107 C 19.209 23.107 17.889 23.507 16.763 24.259 C 15.641 25.01 14.765 26.076 14.247 27.326 C 13.73 28.573 13.594 29.944 13.857 31.271 C 14.122 32.6 14.771 33.815 15.728 34.771 C 16.686 35.729 17.902 36.377 19.229 36.641 C 20.552 36.905 21.926 36.768 23.175 36.254 C 24.425 35.734 25.491 34.859 26.241 33.736 C 26.99 32.612 27.391 31.289 27.391 29.94 C 27.391 28.129 26.673 26.391 25.39 25.108 C 24.109 23.826 22.371 23.107 20.558 23.107 Z"
				fill="black" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)" />
		</>
	);
}

function Border() {
	return (
		<path d="M 45.716 0 L 15.716 0 C 11.738 0 7.923 1.58 5.109 4.394 C 2.296 7.206 0.717 11.022 0.717 14.999 L 0.717 45.001 C 0.717 48.978 2.296 52.794 5.109 55.606 C 7.923 58.42 11.738 60 15.716 60 L 45.716 60 C 49.695 60 53.51 58.42 56.323 55.606 C 59.137 52.794 60.716 48.978 60.716 45.001 L 60.716 14.999 C 60.716 11.022 59.137 7.206 56.323 4.394 C 53.51 1.58 49.695 0 45.716 0 Z M 54.716 45.001 C 54.716 47.387 53.768 49.676 52.081 51.364 C 50.392 53.053 48.104 54.001 45.716 54.001 L 15.716 54.001 C 13.329 54.001 11.041 53.053 9.351 51.364 C 7.664 49.676 6.716 47.387 6.716 45.001 L 6.716 14.999 C 6.716 12.613 7.664 10.324 9.351 8.636 C 11.041 6.947 13.329 5.999 15.716 5.999 L 45.716 5.999 C 48.104 5.999 50.392 6.947 52.081 8.636 C 53.768 10.324 54.716 12.613 54.716 14.999 L 54.716 45.001 Z"
			fill="black"></path>
	);
}

function RollingBorder() {
	return (
		<path d="M 45.716 0 L 15.716 0 C 11.738 0 7.923 1.58 5.109 4.394 C 2.296 7.206 0.717 11.022 0.717 14.999 L 0.717 45.001 C 0.717 48.978 2.296 52.794 5.109 55.606 C 7.923 58.42 11.738 60 15.716 60 L 45.716 60 C 49.695 60 53.51 58.42 56.323 55.606 C 59.137 52.794 60.716 48.978 60.716 45.001 L 60.716 14.999 C 60.716 11.022 59.137 7.206 56.323 4.394 C 53.51 1.58 49.695 0 45.716 0 Z M 54.716 45.001 C 54.716 47.387 53.768 49.676 52.081 51.364 C 50.392 53.053 48.104 54.001 45.716 54.001 L 15.716 54.001 C 13.329 54.001 11.041 53.053 9.351 51.364 C 7.664 49.676 6.716 47.387 6.716 45.001 L 6.716 14.999 C 6.716 12.613 7.664 10.324 9.351 8.636 C 11.041 6.947 13.329 5.999 15.716 5.999 L 45.716 5.999 C 48.104 5.999 50.392 6.947 52.081 8.636 C 53.768 10.324 54.716 12.613 54.716 14.999 L 54.716 45.001 Z"
			fill="#0ea5e9"></path>
	);
}


const MemoDice = memo(Dice);

function Dice({ data, sse }) {
	const [dots, setDots] = useState(data.dots);
	const [isRolling, setRolling] = useState(data.rolling);

	sse.addHandler(data.index, (event) => {
		const d = JSON.parse(event.data)
		setRolling(true)
		const delay = 20
		for (let i = 0; i < d.dots.length; i++) {
			setTimeout(() => setDots(d.dots[i]), delay * (i + 1))
		}
		setTimeout(() => setRolling(false), delay * (d.dots.length + 1))
	})

	let dotsTag
	if (dots === 1 || dots === 0) {
		dotsTag = <One />
	} else if (dots === 2) {
		dotsTag = <Two />
	} else if (dots === 3) {
		dotsTag = <Three />
	} else if (dots === 4) {
		dotsTag = <Four />
	} else if (dots === 5) {
		dotsTag = <Five />
	} else {
		dotsTag = <Six />
	}
	let RollingTag
	if (isRolling) {
		RollingTag = <RollingDice d={dotsTag} />
	} else {
		RollingTag = <IdleDice d={dotsTag} />
	}
	const roll = () => post("/roll-dice/" + data.index);

	return (
		<>
			<Tooltip
				RollingTag={RollingTag}
				diceIndex={data.index + 1}
				roll={roll}
			/>
		</>
	);
}

function Tooltip({ RollingTag, diceIndex, roll }) {
	return (
		<div className="has-tooltip justify-center relative" onClick={roll}>
			<span
				className="invisible text-xs absolute z-10 bottom-8 min-w-16 tooltip text-white bg-zinc-900 p-1 rounded-lg text-center underline border border-zinc-400 right-auto tabular-nums place-self-center -translate-x-1/4">{diceIndex}</span>
			{RollingTag}
		</div>
	);

}

function IdleDice({ d }) {
	return (
		<div className="cursor-pointer size-8 p-px border-2 border-transparent hover:border-green-400 rounded-lg">
			<svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
				<Border />
				{d}
			</svg>
		</div>
	);
}

function RollingDice({ d }) {
	return (
		<div className="cursor-progress size-8 p-px" loading="lazy" sse-swap="{{ .Index }}" hx-swap="outerHTML"
			hx-trigger="revealed delay:1s" hx-get="/refresh-dice/{{ .Index }}">
			<svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
				<RollingBorder />
				{d}
			</svg>
		</div>
	)
}

export { Dice, MemoDice };


function RedDice() {
	return (
		<div className="size-8 border-red-600 border-2 rounded-full cursor-pointer">
			<svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
				<path d="M 42.606 9.965 C 41.027 9.965 39.482 10.434 38.163 11.314 C 36.849 12.194 35.824 13.441 35.217 14.905 C 34.613 16.365 34.453 17.971 34.761 19.524 C 35.072 21.079 35.831 22.503 36.952 23.622 C 38.073 24.743 39.496 25.502 41.05 25.811 C 42.599 26.121 44.209 25.96 45.671 25.357 C 47.134 24.75 48.383 23.725 49.261 22.409 C 50.137 21.094 50.608 19.546 50.608 17.965 C 50.608 15.846 49.766 13.81 48.264 12.308 C 46.765 10.807 44.73 9.965 42.606 9.965 Z"
					fill="#dc2626" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)">
				</path>
				<path d="M 18.823 9.965 C 17.244 9.965 15.699 10.434 14.38 11.314 C 13.066 12.194 12.041 13.441 11.434 14.905 C 10.83 16.365 10.67 17.971 10.978 19.524 C 11.289 21.079 12.048 22.503 13.169 23.622 C 14.29 24.743 15.713 25.502 17.267 25.811 C 18.816 26.121 20.426 25.96 21.888 25.357 C 23.351 24.75 24.6 23.725 25.478 22.409 C 26.354 21.094 26.825 19.546 26.825 17.965 C 26.825 15.846 25.983 13.81 24.481 12.308 C 22.982 10.807 20.947 9.965 18.823 9.965 Z"
					fill="#dc2626" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)">
				</path>
				<path d="M 42.606 34.034 C 41.027 34.034 39.482 34.503 38.163 35.383 C 36.849 36.263 35.824 37.51 35.217 38.974 C 34.613 40.434 34.453 42.04 34.761 43.593 C 35.072 45.148 35.831 46.572 36.952 47.691 C 38.073 48.812 39.496 49.571 41.05 49.88 C 42.599 50.19 44.209 50.029 45.671 49.426 C 47.134 48.819 48.383 47.794 49.261 46.478 C 50.137 45.163 50.608 43.615 50.608 42.034 C 50.608 39.915 49.766 37.879 48.264 36.377 C 46.765 34.876 44.73 34.034 42.606 34.034 Z"
					fill="#dc2626" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)">
				</path>
				<path d="M 18.823 34.034 C 17.244 34.034 15.699 34.503 14.38 35.383 C 13.066 36.263 12.041 37.51 11.434 38.974 C 10.83 40.434 10.67 42.04 10.978 43.593 C 11.289 45.148 12.048 46.572 13.169 47.691 C 14.29 48.812 15.713 49.571 17.267 49.88 C 18.816 50.19 20.426 50.029 21.888 49.426 C 23.351 48.819 24.6 47.794 25.478 46.478 C 26.354 45.163 26.825 43.615 26.825 42.034 C 26.825 39.915 25.983 37.879 24.481 36.377 C 22.982 34.876 20.947 34.034 18.823 34.034 Z"
					fill="#dc2626" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)">
				</path>
				<path d="M 30.715 22 C 29.136 22 27.591 22.469 26.272 23.349 C 24.958 24.229 23.933 25.476 23.326 26.94 C 22.722 28.4 22.562 30.006 22.87 31.559 C 23.181 33.114 23.94 34.538 25.061 35.657 C 26.182 36.778 27.605 37.537 29.159 37.846 C 30.708 38.156 32.318 37.995 33.78 37.392 C 35.243 36.785 36.492 35.76 37.37 34.444 C 38.246 33.129 38.717 31.581 38.717 30 C 38.717 27.881 37.875 25.845 36.373 24.343 C 34.874 22.842 32.839 22 30.715 22 Z"
					fill="#dc2626" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 8.881784197001252e-16)">
				</path>
			</svg>
		</div>
	);
}

export default RedDice;
